import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d7f07a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-fixed bottom-0 w-100" }
const _hoisted_2 = { class: "row gx-0 p-2 text-center" }
const _hoisted_3 = { class: "col-12 col-lg-6 text-lg-start mb-3 mb-lg-0 d-none d-lg-block" }
const _hoisted_4 = { class: "col-3 col-lg-2 d-flex font-size-mobile justify-content-center" }
const _hoisted_5 = { class: "col-5 col-lg-2 d-flex font-size-mobile justify-content-center" }
const _hoisted_6 = { class: "col-4 col-lg-2 d-flex font-size-mobile justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("footer.copyRight")) + " © " + _toDisplayString(new Date().getFullYear()) + " " + _toDisplayString(_ctx.t("footer.copyRightTexto")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "p-0",
          to: { name: 'LegalWarning' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("footer.avisoLegal.title")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, { to: { name: 'ProvacyPolicies' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("footer.polPriv.title")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, { to: { name: 'Cookies' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("footer.polCook")), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}